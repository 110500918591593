
  import { Component, Watch } from 'vue-property-decorator'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import MobileForm from '@/components/forms/view/MobileForm.vue'
  import DesktopForm from '@/components/forms/view/DesktopForm.vue'
  import { BaseCustomForm } from '@/components/person/BasicCustomForm'
  import PhoneReferenceForm from '@/components/person/PhoneReferenceForm.vue'
  import { PersonsDetails } from '@/store/persons/state'
  import { isEmpty } from '@/utils/general'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { Details } from '@/utils/generalInterface'
  import { PersonFormatted } from '@/store/persons/person'

@Component({
  components: { PhoneReferenceForm, DesktopForm, MobileForm, FormTitle, GAlert },
  methods: {
    ...mapActions('persons', ['findReferencePerson']),
    ...mapMutations('persons', ['setReferencePerson']),
    ...mapActions('persons/reference', [
      'loadRelationsShips',
      'createReference',
      'createPersonReference',
    ]),
  },
  computed: {
    ...mapGetters('persons/reference', ['relationShips']),
    ...mapGetters('persons', ['referencePerson', 'disabledReferenceField']),
  },
})
  export default class PersonPhoneReferenceForm extends BaseCustomForm {
  relationShips!: Details[];
  referencePerson!: PersonFormatted;
  disabledReferenceField!: boolean;
  findReferencePerson!: (phone: string) => void;
  setReferencePerson!: (payload) => void
  loadRelationsShips!: () => void
  createReference!: ({ id, personId, relation }: {
    id: number;
    personId: number;
    relation: string;
  }) => Promise<void>;

  createPersonReference!: ({ reference, personId }: {
    reference: PersonsDetails;
    personId: number | null | undefined;
  }) => Promise<void>;

  person: PersonsDetails = {
    id: null,
    phone: '',
    name: '',
    lastName: '',
    surname: '',
    lastSurname: '',
    relationship: '',
  };

  @Watch('referencePerson', { immediate: true, deep: true })
  onReferencePersonChange (val: PersonFormatted): void {
    if (val) {
      this.person.id = val.id
      this.person.name = val.name
      this.person.lastName = val.secondName
      this.person.surname = val.surname
      this.person.lastSurname = val.secondSurname
    }
  }

  async mounted () {
    this.setReferencePerson(null)
    this.loadRelationsShips()
  }

  findPhone (phone: string): void {
    if (!isEmpty(phone) && phone.length > 5) {
      this.person.id = null
      this.person.name = ''
      this.person.lastName = ''
      this.person.surname = ''
      this.person.lastSurname = ''

      this.findReferencePerson(phone)
    }
  }

  async sendPhoneReferenceData (): Promise<void> {
    if (!this.$refs.form.validate()) return
    const { person, getFoundPerson } = this
    this.loadingForm = true
    if (person.id && person.relationship &&
      person.phone
    ) {
      await this.createReference({
        id: person.id,
        personId: getFoundPerson.id,
        relation: person.relationship,
      })
    } else {
      await this.createPersonReference({
        reference: person,
        personId: getFoundPerson.id,
      })
    }

    this.$router.back()
  }
  }
